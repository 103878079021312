import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title className="text-center">
                  Petaler Mobile App Privacy Policy
                </Title>
                <Text variant="small">
                  Your privacy is important to us. It is Petaler's policy to
                  respect your privacy regarding any information we may collect
                  from you through our app, Petaler. <br />
                  <br />
                  We only ask for personal information when we truly need it to
                  provide a service to you. We collect it by fair and lawful
                  means, with your knowledge and consent. We also let you know
                  why we’re collecting it and how it will be used. This
                  information includes your name, email, phone number, address,
                  and/or device information.
                  <br />
                  <br />
                  We only retain collected information for as long as necessary
                  to provide you with your requested service. What data we
                  store, we’ll protect within commercially acceptable means to
                  prevent loss and theft, as well as unauthorized access,
                  disclosure, copying, use or modification.
                  <br />
                  <br /> We don’t share any personally identifying information
                  publicly or with third-parties without your knowledge, except
                  when required to by law. We never sell your information to
                  third-parties.
                  <br /> <br />
                  Our app links to external services that are not operated by
                  us. Please be aware that we have no control over the content
                  and practices of these sites, and cannot accept responsibility
                  or liability for their respective privacy policies. However,
                  we choose third-party services that we believe will be as
                  protective of your data as we are. These services include but
                  are not limited to - Stripe, Plaid, Auth0.
                  <br /> <br />
                  You are free to refuse our request for your personal
                  information, with the understanding that we may be unable to
                  provide you with some of your desired services. Your continued
                  use of our app will be regarded as acceptance of our practices
                  around privacy and personal information. <br />
                  <br />
                  If you have any questions about how we handle user data and
                  personal information, feel free to contact us. This policy is
                  effective as of 7 January 2021.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
